<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
    

      <div id="container">
        <div style="margin-left: 24px;margin-right: 24px;">
          
          <ion-row>
            <ion-col 
              sizeLg="4" 
              sizeMd="4" 
              sizeXs="12" 
            >
              <ion-searchbar  
                @ionChange="getSearch" 
                v-model="search" 
                show-cancel-button="focus" 
                debounce="1000" 
                placeholder="Buscar siniestro" 
                cancel-button-text="Custom Cancel"
              >
             </ion-searchbar>
            </ion-col>
            <ion-col  
              sizeLg="3" 
              sizeMd="3" 
              sizeXs="12"
            >
              <ion-select  
                class="input-text" 
                v-model="status_siniestro" 
                @ionChange="getSearch()" 
                placeholder="Estatus de siniestro" 
                interface="action-sheet"  
                style="color: #000;margin-top: 5px" 
              > 
                <ion-select-option value="0">Activo</ion-select-option>
                <ion-select-option value="1">Archivado</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col 
              sizeLg="3" 
              sizeMd="3" 
              sizeXs="12"
            >
              <ion-select 
                class="input-text" 
                placeholder="Filtrar por Status" 
                interface="action-sheet" 
                @ionChange="getSearch" 
                v-model="colaborador_id" 
                style="color: #000;margin-top: 5px" 
              > 
                <ion-select-option value="">Colaborador</ion-select-option>
                <ion-select-option 
                  v-for="colaborador in colaboradores" 
                  :value="colaborador.id" 
                  :key="colaborador" 
                >
                  {{
                    colaborador.name == null ?  
                    colaborador.user_name : 
                    colaborador.name 
                  }}
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row> 
          
          <div class="card">
            <table class="table table-responsive-sm table-striped table-align-middle">
              <thead>
                <tr>
                  <th>Nro. expediente </th>
                  <th>Colaborador</th>
                  <th>Clientes</th>
                  <th>Fecha de registro</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <DataSiniestro :siniestros="siniestros" @reload="getSiniestrosByUser()"/>
            </table>
            <Paginate :data="siniestros" @page="page($event)"/>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import { 
  defineComponent,
  ref 
} from 'vue';

import { 
  IonButtons, 
  IonContent,
  IonHeader,
  IonSelect,
  IonSelectOption, 
  IonSearchbar, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar 
} from '@ionic/vue';

import { 
  mapGetters 
} from "vuex";

import Pusher from 'pusher-js'
import axios from 'axios'
import DataSiniestro from '@/components/DataSiniestro'
import Paginate from '@/components/Paginate'

export default defineComponent({
  name: 'FolderPage',
  components: {
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    DataSiniestro,
    Paginate
  },
  setup(){
    const isOpenRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    
    return {  
      isOpenRef,
      setOpen,
    }
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  data(){
    return{
      BasePublic: axios.defaults.baseURL,
      siniestros : [],
      colaboradores: [],
      colaborador_id: '',
      status_siniestro : "0",
      search : '',
      siniestro : null,
      message :  null,
      chat_id: null,
      options:[],
      pusher : null,
      status: '',
      localStorage: 'index_siniestro_despacho'
    }
  },
  mounted(){
    this.getColaboradoresByUser()
    if(window.localStorage.getItem(this.localStorage)){
      this.page(window.localStorage.getItem(this.localStorage))
    }else{
      this.getSiniestrosByUser()
    } 
    
    this.pusher = new Pusher('1c14caa5f8d38f7808c0', {
      cluster: 'ap3'
    });

    var channel = this.pusher.subscribe('despacho');
    
    var self = this

    channel.bind('escritorio', function(data) {
      if (data.user_id != self.getUser.id && data.level != 0) {
        const music = new Audio('/assets/sounds/Plink.mp3');
        music.play();
        if(window.localStorage.getItem(self.localStorage)){
          self.page(window.localStorage.getItem(self.localStorage))
        }else{
          self.getSiniestrosByUser()
        }
      }
    });

  },
  methods:{
    getSearch(){
      if (this.search == '' && this.status_siniestro == "0") {
        this.getSiniestrosByUser();
        return;
      }
      
      axios.get('/api/siniestros/filter/like?search='+this.search+'&finalizado='+this.status_siniestro+'&status=true&colaborador_id='+this.colaborador_id)
      .then(res => {
        console.log(res.data.data)
        this.siniestros = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    read_at(){
      axios.put('/api/chats/'+this.chat_id+'/read_at')
      .catch(error => {
        console.log(error)
      })
    },
    getSiniestrosByUser(){
      axios.get('/api/siniestros/by/user?finalizado='+this.status_siniestro+'&status=true&colaborador_id='+this.colaborador_id)
      .then(res => {
        console.log(res.data.data)
        this.siniestros = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getColaboradoresByUser(){
       axios.get('/api/users/colaborador/byUser?all=true')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    page(url){
      
      if (url == null) {
        return;
      }

      window.localStorage.setItem(this.localStorage, url);
      
      axios.get(url+'&search='+this.search+'&finalizado='+this.status_siniestro+'&status=true&colaborador_id='+this.colaborador_id)
       .then(res => {
         this.siniestros = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        window.localStorage.removeItem(this.localStorage);
        console.log(error);
      });
    },
  }
});
</script>
<style type="text/css">

</style>
<style scoped>


ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}

.input-text{
  margin-bottom: 15px;
  padding-left: 17px;
  background: #F5F5F5;
  border: 1px solid rgba(183, 183, 183, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  width :100%;
  height : 48px;
}

.input-text-2{
  margin-bottom: 15px;
  padding-left: 17px;
  background: #F5F5F5;
  border: 1px solid rgba(183, 183, 183, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  width :90%;
  height : 48px;
}

</style>